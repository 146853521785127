<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.setting.safty");
    this.items = [
      {
        text: this.$t("menuitems.setting.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.setting.safty"),
        active: true,
      },
    ];
    
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    this.getSafyinfo();
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
  },
  methods: {
    getSafyinfo() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "safyinfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.safeinfo = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    bindMFAdevice() {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getauthenticator",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that
              .$alert(
                "<p><img src='" +
                  response.data.qrcode +
                  "' width='100%'/></p><p style='text-align:center'>" +
                  response.data.secret +
                  "</p>",
                that.$t("setting.safty.text.bindsafytool", {
                  tool: that.$t("setting.safty.mfa"),
                }),
                {
                  dangerouslyUseHTMLString: true,
                  confirmButtonText: that.$t("setting.safty.text.next"),
                }
              )
              .then(() => {
                that
                  .$prompt(
                    that.$t("setting.safty.text.authcode"),
                    that.$t("global.waring"),
                    {
                      confirmButtonText: that.$t("global.button.ok"),
                      cancelButtonText: that.$t("global.button.cancel"),
                    }
                  )
                  .then(({ value }) => {
                    that.voidMFAdevice(response.data.secret, value);
                  })
                  .catch(() => {});
              })
              .catch((action) => {});
          } else if ((response.data.status = 202)) {
            that.$message.error(that.$t("setting.safty.text.mfaenable"));
          } else {
            that.$message.error(that.$t("global.failed"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    voidMFAdevice(secret, code) {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "voidauthenticator",
            secret,
            code,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          that.getSafyinfo();
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else if ((response.data.status = 202)) {
            that.$message.error(that.$t("setting.safty.text.mfaenable"));
          } else {
            that.$message.error(that.$t("global.failed"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    removeMFAdevice() {
      var that = this;
      this.$confirm(
        that.$t("setting.safty.text.unlinktip", {
          tool: that.$t("setting.safty.mfa"),
        }),
        that.$t("global.waring"),
        {
          confirmButtonText: that.$t("global.button.ok"),
          cancelButtonText: that.$t("global.button.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          that
            .$prompt(
              that.$t("setting.safty.text.authcode"),
              that.$t("global.waring"),
              {
                confirmButtonText: that.$t("global.button.ok"),
                cancelButtonText: that.$t("global.button.cancel"),
              }
            )
            .then(({ value }) => {
              that.$axios
                .post(
                  that.apiuri,
                  {
                    action: "removeauthenticator",
                    code: value,
                  },
                  {
                    headers: { Openid: that.userinfo.openid },
                  }
                )
                .then(function (response) {
                  that.$toast.clear();
                  that.getSafyinfo();
                  if (response.data.status == 200) {
                    that.$message({
                      message: that.$t("setting.safty.text.unlinksuccess", {
                        tool: that.$t("setting.safty.mfa"),
                      }),
                      type: "success",
                    });
                  } else {
                    that.$message.error(that.$t("global.failed"));
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    bindWechat() {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          that.apiuri,
          {
            action: "wechatoauthcode",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that
              .$alert(
                "<p><img src='https://paygay.aic.xin/qr.png?data=" +
                  response.data.img +
                  "' width='100%'/></p><p style='text-align:center'>" +
                  that.$t("setting.safty.text.wechattip") +
                  "</p>",
                that.$t("setting.safty.text.bindsafytool", {
                  tool: that.$t("setting.safty.wechat"),
                }),
                {
                  dangerouslyUseHTMLString: true,
                }
              )
              .then(() => {
                that.getSafyinfo();
              })
              .catch((action) => {});
          } else if ((response.data.status = 202)) {
            that.$message.error(that.$t("setting.safty.text.wechatenable"));
          } else {
            that.$message.error(that.$t("global.failed"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    changeWechat() {
      var that = this;
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          that.apiuri,
          {
            action: "removewehchatoauth",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that
              .$alert(
                "<p><img src='https://paygay.aic.xin/qr.png?data=" +
                  response.data.img +
                  "' width='100%'/></p><p style='text-align:center'>" +
                  that.$t("setting.safty.text.wechattip") +
                  "</p>",
                that.$t("setting.safty.text.changetool", {
                  tool: that.$t("setting.safty.wechat"),
                }),
                {
                  dangerouslyUseHTMLString: true,
                  confirmButtonText: that.$t("setting.safty.text.next"),
                }
              )
              .then(() => {
                that.$axios
                  .post(
                    that.apiuri,
                    {
                      action: "checkwehchatoauth",
                    },
                    {
                      headers: { Openid: that.userinfo.openid },
                    }
                  )
                  .then(function (response) {
                    if (response.data.status == 404) {
                      that.bindWechat();
                      that.getSafyinfo();
                    } else {
                      that.getSafyinfo();
                      that.$message.error(that.$t("setting.safty.text.noscan"));
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
              .catch((action) => {});
          } else if ((response.data.status = 202)) {
            that.$message.error(that.$t("setting.safty.text.mfaenable"));
          } else {
            that.$message.error(that.$t("global.failed"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    bindPhone() {
      var that = this;
    },
    changePhone() {
      var that = this;
    },
    bindEmail() {
      var that = this;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-tabs pills justified content-class="p-3 text-muted">
              <b-tab
                :title="$t('setting.safty.base')"
                active
                title-item-class="mb-2"
              >
                <b-card-text v-loading="loading">
                  <table class="table mb-0">
                    <tbody>
                      <tr>
                        <td>{{ $t("setting.safty.mfa") }}</td>
                        <td>
                          {{ $t("setting.safty.text.mfa") }}
                        </td>
                        <td v-if="safeinfo.authkey">
                          <span style="color: #1cbb8c">{{
                            $t("setting.safty.text.mfaenable")
                          }}</span>
                          <a href="javascript:;" @click="removeMFAdevice">{{
                            $t("setting.safty.button.unlink")
                          }}</a>
                        </td>
                        <td v-else>
                          <span style="color: #ff3d60">{{
                            $t("setting.safty.text.mfadisable")
                          }}</span>
                          <a href="javascript:;" @click="bindMFAdevice">{{
                            $t("setting.safty.button.link")
                          }}</a>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t("setting.safty.phone") }}</td>
                        <td>
                          {{ $t("setting.safty.text.phone") }}
                        </td>
                        <td v-if="safeinfo.phone">
                          {{ safeinfo.phone }}
                          <a href="javascript:;">{{
                            $t("setting.safty.button.change")
                          }}</a>
                        </td>
                        <td v-else>
                          {{
                            $t("setting.safty.text.bindnull", {
                              reoute: $t("setting.safty.phone"),
                            })
                          }}
                          <a href="javascript:;">{{
                            $t("setting.safty.button.link")
                          }}</a>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t("setting.safty.email") }}</td>
                        <td>
                          {{ $t("setting.safty.text.email") }}
                        </td>
                        <td v-if="safeinfo.email">
                          {{ safeinfo.email }}
                          <a href="javascript:;">{{
                            $t("setting.safty.button.change")
                          }}</a>
                        </td>
                        <td v-else>
                          {{
                            $t("setting.safty.text.bindnull", {
                              reoute: $t("setting.safty.email"),
                            })
                          }}
                          <a href="javascript:;">{{
                            $t("setting.safty.button.link")
                          }}</a>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t("setting.safty.wechat") }}</td>
                        <td>
                          {{ $t("setting.safty.text.wechat") }}
                        </td>
                        <td v-if="safeinfo.wxopenid">
                          {{ $t("setting.safty.text.wechatenable") }}
                          <a href="javascript:;" @click="changeWechat">{{
                            $t("setting.safty.button.change")
                          }}</a>
                        </td>
                        <td v-else>
                          {{ $t("setting.safty.text.wechatdisable") }}
                          <a href="javascript:;" @click="bindWechat">{{
                            $t("setting.safty.button.link")
                          }}</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-text>
              </b-tab>
              <b-tab
                :title="$t('setting.safty.account')"
                title-item-class="mb-2"
              >
                <b-card-text> <el-skeleton /></b-card-text>
              </b-tab>
              <b-tab
                :title="$t('setting.safty.action')"
                title-item-class="mb-2"
              >
                <b-card-text><el-skeleton /></b-card-text>
              </b-tab>
              <b-tab
                :title="$t('setting.safty.delete')"
                title-item-class="mb-2"
              >
                <b-card-text><el-skeleton /></b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>